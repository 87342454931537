@import "../../variables.scss";

.cta {
  padding: $gutter-xl;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ctaHeading {
  font-size: 3rem;
}

.heading {
  margin: $gutter-xs 0 $gutter-lg 0;
  text-align: center;
}

.icon {
  height: 60px;
  width: 60px;

  img {
    height: 100%;
  }
}
