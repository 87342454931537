@import "../../variables.scss";
@import "../../mixins.scss";

.infoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 7.5vh;

  padding: 0 $gutter;
}

.content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: $gutter-xl;

  flex-direction: column;

  @include respond-to("medium") {
    flex-direction: row;
  }
}

.section {
  flex: 1;
  position: relative;
}

.heading {
  text-transform: uppercase;
  color: $primary-dark;
  margin: $gutter-sm 0 7.5vh 0;
}

.icon {
  border-radius: 50%;
  background-image: linear-gradient(to right, $primary, $primary-dark);
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7.5vh;
}
