@import "../../variables.scss";
@import "../../mixins.scss";

.header {
  display: flex;
  align-items: center;
  padding: $gutter-lg $gutter-sm;
  margin-bottom: 4vh;
  background-image: linear-gradient(to right, $primary, $primary-light);
}

.transparent {
  background: transparent;
}

.content {
  height: $gutter-lg;
  width: 100%;
  max-width: $content-width;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

.products {
  display: flex;
}

.products {
  flex: 1;
  justify-content: center;
  align-items: center;
}

.products,
.actions {
  display: none;

  @include respond-to("medium") {
    display: flex;
  }
}

.mobileMenu {
  display: block;

  @include respond-to("medium") {
    display: none;
  }
}
