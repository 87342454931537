@import "../../variables.scss";
@import "../../mixins.scss";

$smWidth: 320px;
$mdWidth: 720px;
$lgWidth: 875px;

.content {
  display: flex;
  width: $smWidth;
  flex-wrap: wrap;
}

.main {
  flex: 1;
  padding: $gutter;
}

.menu {
  position: absolute;
  top: 40px;
  left: -($smWidth / 2) + 55px;

}