@import "../../variables.scss";

.button {
  border: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: $gutter-xs $gutter;
  min-width: $input-width;
  cursor: pointer;
  position: relative;

  font: inherit;
  font-size: $font-size-sm;
  border-radius: $radius;
  text-decoration: none;
  text-transform: uppercase;
  height: 36px;

  box-shadow: 0 4px 6px rgba(50, 50, 94, 0.12), 0 1px 3px rgba(0, 0, 0, 0.09);

  &:hover {
    opacity: 0.75;
  }

  &:focus {
    outline: 1px solid $primary-light;
  }
}

.iconAfter {
  position: absolute;
  right: $gutter;
  top: 0;
  bottom: 0;
  align-items: center;
  display: flex;
}

.withIcon {
  padding-right: $gutter-lg;
}

.select {
  background: $border;
  width: $input-width;
  text-transform: unset;
}

.block {
  width: 100%;
}

.none {
  background: transparent;
  text-transform: unset;
  font-size: $font-size;
  box-shadow: none;
  min-width: 0;
}

.light {
  background: #fff;
  color: black;
}

.primary {
  background: $primary;
}

.dark {
  background: $primary-dark;
}

.cancel {
  background: red;
}
