@import "../../variables.scss";

.heroTextWrapper {
  padding: $gutter-lg 0;
  color: #fff;
  width: 100%;
  max-width: 550px;

  p {
    color: $faint;
  }
}

.description {
  font-size: $font-size;
  margin: $gutter 0;
  color: $faint;
  line-height: inherit;
  opacity: 0.8;
}

.dark {
  color: #000;
}

.actions {
  margin-top: $gutter-lg;
}

.supertitle {
  text-transform: uppercase;
  font-size: $font-size-sm;
  color: $primary-light;
  letter-spacing: 0.1rem;
}
