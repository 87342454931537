@import '../../variables.scss';

.anchor {
    text-decoration: none;
    // color: #000;

    &:hover {
        opacity: 0.6;
    }

    &:focus {
        outline: 1px solid $primary-light;

        > li {
            outline: 1px solid $primary-light;
        }
    }
}