@import "../../variables.scss";

.infoCard {
  display: flex;
  align-items: center;
  position: relative;

  padding: $gutter-lg;
  padding-left: 0;
  overflow: hidden;

  transition-duration: 0.2s;

  box-shadow: 0 15px 28px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -5px 15px -5px rgba(0, 0, 0, 0.025);

  &:hover {
    transform: translateY(-2px);

    box-shadow: 0 15px 35px -5px rgba(50, 50, 93, 0.275),
    0 8px 20px -8px rgba(0, 0, 0, 0.325), 0 -5px 15px -5px rgba(0, 0, 0, 0.05);
  }

  &:after {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      background: $color-offWhite;
      transform: rotate(45deg) translateY(60%);
      height: 150%;
  }
}

.heading {
  text-transform: uppercase;
  color: $primary-dark;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: $gutter-lg;
}
