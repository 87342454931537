@import "../../variables.scss";
@import "../../mixins.scss";

.footer {
  width: 100%;
  padding: $gutter;
  margin-top: $gutter-xl;

  @include respond-to("medium") {
    padding: 0 0 $gutter-xl 0;
  }
}

.content {
  width: 100%;
  max-width: $content-width;
  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;

  border-top: 1px solid $border-dark;
  padding-top: $gutter-xl;

  &.contentDark {
    border-top: 1px solid $border;
  }
}

.section {
  flex: 1;
  min-width: 200px;
  text-align: center;

  ul {
    list-style-type: none;
    padding-inline-start: 0px;
  }

  li {
    min-height: 48px;
    color: $faint-text;
    a {
      padding: 15px 0;
    }
  }

  @include respond-to("small") {
    text-align: left;

    li {
      min-height: unset;
      a {
        padding: 0;
      }
    }

    &:not(:last-of-type) {
      margin-right: $gutter-lg;
      padding-right: $gutter-lg;
    }
  }
}

.dark,
.primary {
  color: #fff;

  li {
    color: $faint;
    opacity: 0.8;

    &:focus {
      outline: 1px solid $border;
    }
  }
}

.extra {
  width: 100%;
  margin-top: $gutter-xl;
  color: $faint-text;
  opacity: 0.75;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;

  @include respond-to("small") {
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
  }

  .social {
    margin-bottom: $gutter;
  }

  .socialIcon {
    margin-right: $gutter-sm;
  }

  .heart {
    color: #bb0000;
  }

  .attributions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
