@import "../../variables.scss";

.dropdown {
  position: relative;
  z-index: 50;

  button[aria-expanded="true"] {
    opacity: 0.75;
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 200%;
  top: -10px;
  cursor: pointer;
}
