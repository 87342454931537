@import "../../variables.scss";
@import "../../mixins.scss";

$smWidth: 320px;
$mdWidth: 720px;
$lgWidth: 875px;

.content {
  display: flex;
  width: $smWidth;
  flex-wrap: wrap;

  @include respond-to("medium") {
    width: $mdWidth;
  }

  @include respond-to("large") {
    width: $lgWidth;
  }
}

.main {
  flex: 4;
  padding: $gutter;
}

.sub {
  flex: 3;
  padding: $gutter;
  background: $faint;
}

.menu {
  position: absolute;
  top: 40px;
  left: -($smWidth / 2) + 55px;

  @include respond-to("medium") {
    left: -($mdWidth / 2) + 55px;
  }

  @include respond-to("large") {
    left: -($lgWidth / 2) + 55px;
  }
}