@import "../../variables.scss";

.heading {
  margin: 0;
  line-height: 1.2;
}

.normal {
  font-weight: normal;
}

.h1 {
  font-size: 3.2rem;
  margin: $gutter 0;
}

.h2 {
  font-size: 2.2rem;
  margin: $gutter-sm 0;
}

.h3 {
  font-size: 1.8rem;
  margin: $gutter-sm 0;
}

.h4 {
  font-size: 1.7rem;
  margin: $gutter-sm 0;
}

.h5 {
  font-size: 1.6rem;
  margin: $gutter-xs 0;
}

.h6 {
  font-size: 1.6rem;
  margin: $gutter-xs 0;
}
