.svg {
  width: 50px;
  height: auto;

  &.xs {
    width: 30px;
  }

  &.small {
    width: 35px;
  }

  &.full {
    width: unset;
  }

  img {
    width: 100%;
    height: auto;
  }
}
