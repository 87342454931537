@import "../../variables.scss";
@import "../../mixins.scss";

.heroWrapper {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero {
  position: relative;
  height: 80vh;

  @include respond-to("medium") {
    height: 60vh;
  }

  @include respond-to("large") {
    height: 80vh;
  }

  &.light {
    background-image: linear-gradient(to right, $primary, $primary-light);
  }

  &.primary {
    background-image: linear-gradient(to right, $primary-dark, $primary);
  }

  &.dark {
    background-image: linear-gradient(
      to right,
      $primary-emphasis,
      $primary-dark
    );
  }

  svg {
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 25vw;
    fill: $pageBg;
  }
}
