@import "../../variables.scss";
@import "../../mixins.scss";

.card {
  border-radius: $radius;
  background: $cardBg;
  // width: fit-content;
  width: 100%;
  flex: 1;

  box-shadow: 0 25px 50px -20px rgba(50, 50, 93, 0.15),
    0 30px 50px -30px rgba(0, 0, 0, 0.3),
    0 -18px 60px -10px rgba(0, 0, 0, 0.025);

  &.padded {
    padding: $gutter;

    @include respond-to("small") {
      padding: $gutter-lg;
    }
  }
}

.paddedTitle {
  padding: $gutter;
}

.center {
  text-align: center;
}

.withArrow {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -7.5px;
    width: 15px;
    height: 15px;
    background: $cardBg;
    transform: rotate(45deg);
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.title {
  min-width: 100%;
  max-width: 100%;
}

.clickable {
  transition-duration: 200ms;
  &:hover {
    cursor: pointer;
    background: rgba(245, 245, 245, 1);
    // transform: scaleY(1.0125);
    // opacity: 0.95;
  }
}
