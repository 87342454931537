$gutter-sm: 10px;
$gutter-xs: 5px;
$gutter-sm: 10px;
$gutter: 15px;
$gutter-md: 20px;
$gutter-lg: 30px;
$gutter-xl: 45px;

$content-width: 1080px;
$content-width-wide: 1440px;

$primary: #0488d5;
$primary-light: #5fb8ff;
$primary-dark: #005ca3;

$color-offWhite: rgba(0, 0, 0, 0.0175);

$primary-emphasis: #003374; // button

$pageBg: #fff;
$cardBg: #fff;
$grayBg: #cfd8dc;

$font-size-sm: 1.5rem;
$font-size: 1.7rem;
$font-size-lg: 1.9rem;

$font-size-title: 3.4rem;
$font-size-subtitle: 2.2rem;

$radius: 2px;

$input-height: 60px;
$input-width: 200px;

// FAINT: "#EEEEEE",
// FAINT_TEXT: "rgba(255, 255, 255, 0.75)",
// FAINT_DARK: "rgba(0, 0, 0, 0.4)",
// DARK: "rgba(0, 0, 0, 0.75)",
// BORDER: "rgba(255, 255, 255, 0.5)",

$faint: rgba(#eee, 0.8);
$faint-text: rgba(0, 0, 0, 0.55);

$border: rgba(255, 255, 255, 0.2);
$border-dark: rgba(0, 0, 0, 0.125);

$breakpoints: (
  "xs": 480px,
  "small": 600px,
  "medium": 767px,
  "large": $content-width,
  "xl": $content-width-wide
) !default;
