@import "../../variables.scss";

.section {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  display: flex;
  justify-content: center;
}

.contained {
  max-width: $content-width;

  &.wide {
    max-width: $content-width-wide;
  }
}

.dark {
  background: $primary-dark;
}

.emphasis {
  background: $primary-emphasis;
}

.light {
  background: #fff;
}

.faint {
  background: $color-offWhite;
}

.transparent {
  background: transparent;
}
