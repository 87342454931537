@import "../../variables.scss";

.item {
  display: flex;
  padding-bottom: $gutter;
}

.small,
.noIcon {
  padding-bottom: $gutter-sm;
}

.noMargin {
  padding-bottom: 0;
}

.noIcon {
  padding-left: 50px;

  &:first-of-type {
    margin-top: -$gutter;
  }
}

.content {
  padding: 0 $gutter;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h5,
  h6 {
    color: #000;
  }

  p {
    margin-top: $gutter-xs;
  }
}

.circle {
  padding: 7px;
  border-radius: 50%;
}
