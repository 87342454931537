@import "../../variables.scss";
@import "../../mixins.scss";

.atom {
  transform: rotateY(23deg) skew(-3deg) scale(1);
  margin: $gutter-lg 0;

  @include respond-to("small") {
    transform: rotateY(25deg) skew(-5deg) scale(1.2) translate(10%, 5%);
  }
}

.ring {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 1px 1px 1px 2px rgba(#000, 0.06),
    -1px -1px 0px 2px rgba(#000, 0.06);
}

.ringOuter {
  background: rgba(#fff, 0.3);
  animation: spin-right 60s linear infinite;
  box-shadow: 1px 1px 40px 4px rgba(0, 0, 0, 0.1),
    1px -1px 40px 5px rgba(0, 0, 0, 0.08);

  width: 320px;
  height: 320px;

  @include respond-to("small") {
    width: 475px;
    height: 475px;
  }
}

.ringMiddle {
  width: 75%;
  height: 75%;
  background: rgba(#fff, 0.3);
  transform: rotateZ(45deg);

  img {
    transform: rotate(-45deg);
  }
}

.ringInner {
  width: 60%;
  height: 60%;
  background: rgba(#fff, 0.3);
}

.icon {
  width: 50px;
  height: 50px;
  position: absolute;
  animation: spin-left 60s linear infinite;

  img {
    width: 100%;
  }

  &_top {
    top: -25px;
  }

  &_right {
    right: -25px;
  }

  &_left {
    left: -25px;
  }

  &_bottom {
    bottom: -25px;
  }
}

.logo {
  background: $primary;
  border-radius: 50%;
  box-shadow: 2px 3px 13px 2px rgba(0, 0, 0, 0.26);

  img {
    width: 110%;
    height: 110%;
    margin-top: -2px;
    margin-left: -2px;
  }
}

@keyframes spin-right {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin-left {
  100% {
    -webkit-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
