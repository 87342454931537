.logo {
  width: 100%;
  height: 100%;
  max-width: 200px;
  display: flex;

  &.center {
    margin: 0 auto;
  }

  img {
    width: 100%;
    height: auto;
  }

  &.withText {
    img {
      max-height: 40px;
    }
  }
}
