@import '../../variables.scss';

.text {
    font-size: $font-size;
    margin: $gutter 0;
    color: lighten($color: #000000, $amount: 30%)
}

.faint {
    color: $faint;
    opacity: 0.8;
}

.small {
    font-size: $font-size-sm;
    line-height: 1.5;
}