@import "../../variables.scss";
@import "../../mixins.scss";

.content {
  min-height: 66vh;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.code {
  font-size: 12rem;
  line-height: 12rem;
  flex: 1;

  display: flex;
  align-items: center;

  @include respond-to("small") {
    justify-content: flex-end;
    padding-right: $gutter-lg;
    border-right: 2px solid $border;
    margin-right: $gutter-lg;
  }
}

.sorry {
  flex: 1;

  p {
    font-size: $font-size-lg;
  }
}

.return {
  margin-top: $gutter-xl;
}

.main {
  display: flex;
  flex-wrap: wrap;
}

.code,
.sorry {
  flex: 1;
  min-width: 300px;

  @include respond-to("medium") {
    min-width: 400px;
  }
}

.option {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  h5 {
    margin-right: $gutter-xs;
  }
}
