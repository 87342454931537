@import "../../variables.scss";

.mobileMenu {
  //   position: absolute;
  //   top: 0;
  //   padding: $gutter-xl;
  //   height: 100vh;
  //   width: 100vw;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list {
  display: flex;
  flex-direction: column;
}

.main {
  padding: $gutter-md 0 $gutter;
  margin-bottom: $gutter-md;
  border-bottom: 1px solid $border-dark;
}

.bottomFixed {
  flex: 1;
  margin-top: $gutter-md;
  padding: $gutter-md 0 $gutter-xl;
  border-top: 1px solid $border-dark;
}
