@import "../../variables.scss";
@import "../../mixins.scss";

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: $gutter-xl;
}

.infoSide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.content {
  margin-bottom: $gutter;
}

.media {
  height: 100%;
  display: flex;
  margin-left: 20%;
  position: relative;

  .image {
    z-index: 1;
    left: 0;
    position: absolute;
    height: 100%;
    width: auto;

    box-shadow: 0 25px 50px -20px rgba(50, 50, 93, 0.15),
      0 30px 50px -30px rgba(0, 0, 0, 0.3),
      0 -18px 60px -10px rgba(0, 0, 0, 0.025);

    &_far {
      z-index: 2;
      margin-left: 75%;
      margin-top: 25%;

      box-shadow: -25px 20px 50px -20px rgba(50, 50, 93, 0.15),
        -35px 45px 50px -30px rgba(0, 0, 0, 0.6),
        0 -18px 60px -10px rgba(0, 0, 0, 0.425);
    }
  }

  &_left {
    margin-left: 0;
    margin-right: 20%;
    .image {
      right: 0;
      left: unset;
      &_far {
        margin-left: unset;
        margin-right: 75%;
        margin-bottom: 25%;
      }
    }
  }
}

.heroText {
  z-index: 2;
}

.heroAtom {
  position: absolute;
  right: -10%;
  top: 3%;
  opacity: 0.125;

  @include respond-to("medium") {
    opacity: 0.25;
    top: 2.5%;
    right: -5%;
  }

  @include respond-to("large") {
    position: unset;
    opacity: 1;
  }
}

.sheetLink {
  height: 48px;

  @include respond-to("small") {
    height: 36px;
  }
}
