@import "../../variables.scss";

.pro {
  font-size: $font-size-sm;
  margin: 0 $gutter-xs;
  background: $faint-text;
  //   background: $primary-emphasis;
  border-radius: 10px;
  padding: $gutter-xs $gutter;
  display: inline-flex;
  align-items: center;
  height: 30px;
  color: #fff;
}
