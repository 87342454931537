@import '../../variables.scss';
@import '../../mixins.scss';

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

.dark {
    background-image: linear-gradient(to right, $primary-emphasis, $primary-dark);
}

.primary {
    background-image: linear-gradient(135deg, $primary, $primary-dark);
}

.content {
    width: 100%;
    max-width: $content-width;
    margin: 0 auto;
    flex: 1;
    padding: 0 $gutter;

    @include respond-to('large') {
        padding: 0;
    }
}

.breakout {
    max-width: $content-width-wide
}